import {baseApiService} from "../baseApiService";
import {
    ConfirmEmailRequest,
    ForgotPasswordRequest,
    LoginRequest,
    MessageResponse,
    ResetPasswordRequest,
    UnsubscribeEmailRequest
} from "../../models/LoginRequest";
import {UserResponse} from "../../models/UserResponse";
import {ChangePasswordRequest, CreateUserRequest, Profile} from "../../models/Profile";

export const authApi = baseApiService.enhanceEndpoints({ addTagTypes: ["Profile"] }).injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<UserResponse, LoginRequest>({
            query: (credentials) => ({
                url: 'tokens',
                method: 'POST',
                body: credentials,
            }),
        }),
        resetPassword: builder.mutation<MessageResponse, ResetPasswordRequest>({
            query: (resetData) => ({
                url: 'users/reset-password',
                method: 'POST',
                body: resetData,
            }),
        }),
        forgotPassword: builder.mutation<MessageResponse, ForgotPasswordRequest>({
            query: (fpData) => ({
                url: 'users/forgot-password',
                method: 'POST',
                body: fpData,
            }),
        }),
        profile: builder.query<Profile, void>({
            query: () => 'personal/profile',
            providesTags:["Profile"]
        }),
        updateProfile: builder.mutation<Profile, void>({
            query: (req) => ({
                url: 'personal/profile',
                method: 'PUT',
                body: req,
            }),
            invalidatesTags:["Profile"]
        }),
        createAccount: builder.mutation<string, CreateUserRequest>({
            query: (req) => ({
                url: 'users',
                method: 'POST',
                body: req,
                responseHandler: (response: { text: () => any }) => response.text()
            }),
            invalidatesTags:["Profile"]
        }),
        changePassword: builder.mutation<void, ChangePasswordRequest>({
            query: (req) => ({
                url: 'personal/change-password',
                method: 'PUT',
                body: req
            })
        }),
        protected: builder.mutation<{ message: string }, void>({
            query: () => 'protected',
        }),
        confirmEmail: builder.mutation<MessageResponse, ConfirmEmailRequest>({
            query: ({code,userId,tenant}) => ({
                url: `users/confirm-email?code=${code}&userId=${userId}&tenant=${tenant}`,
                method: 'GET'
            }),
        }),
        unsubscribeEmail: builder.mutation<MessageResponse, UnsubscribeEmailRequest>({
            query: ({userEmail,tenant}) => ({
                url: `users/unsubscribe-email?userEmail=${userEmail}&tenant=${tenant}`,
                method: 'GET'
            }),
        })
    }),
})

export const { useLoginMutation, useProfileQuery, useProtectedMutation, useChangePasswordMutation, useCreateAccountMutation  ,useResetPasswordMutation, useForgotPasswordMutation, useConfirmEmailMutation, useUnsubscribeEmailMutation, useUpdateProfileMutation } = authApi
