import {AiOutlineAppstore, IoHomeOutline, IoNotificationsOutline,AiOutlineTeam,AiOutlineSchedule} from "react-icons/all";
import React from "react";
import {AdminRoles} from "../../../global/constants";
import {IsArrayWithLength, UserHasRoles} from "../../../utilities/extensions";

const CoreSideMenuItems = [
    {
        key: 'dashboard',
        icon: <IoHomeOutline  />,
        label: 'Dashboard',
        path:'dashboard'
    },
    {
        key: 'test',
        icon: <AiOutlineAppstore />,
        label: 'Test',
        to:'/test',
        children:[
            {
                key: 'test-child',
                icon: <IoNotificationsOutline />,
                label: 'Test Child'
            }
        ]
    }
];

const SideMenuItems = [];
export const MenuRoles = {};

export const ReLoadMenuItems = () =>{
    ClearMenuItems();
    CoreSideMenuItems.forEach(m=>{
        let menu = undefined;
        if(m.roles === undefined || UserHasRoles(m.roles)) menu = {...m, children: []};
        if(IsArrayWithLength(m.roles)) MenuRoles[`/${m.key}`] = [...m.roles];

        if(IsArrayWithLength(m.children)){
            const children = m.children.filter(cm=> IsArrayWithLength(cm.roles));
            if(IsArrayWithLength(children)){
                children.forEach(cm=>{
                    MenuRoles[`/${m.key}/${cm.key}`] = [...cm.roles];
                })
            }
            m.children.filter(cm=> cm.roles === undefined || UserHasRoles(cm.roles)).forEach(cm=>{
                menu.children.push({...cm});
            });
        }
        if(menu !== undefined) SideMenuItems.push(menu)

    });
}

export const ClearMenuItems = () =>{
    SideMenuItems.length = 0;
    Object.keys(MenuRoles).forEach(key => {
        delete MenuRoles[key];
    })
}

if(!IsArrayWithLength(SideMenuItems) || !IsArrayWithLength(MenuRoles)) ReLoadMenuItems();

export default SideMenuItems
