import React from 'react';
import {useDispatch} from "react-redux";
import {useAuth} from "../../hooks/useAuth";
import {Breadcrumb, Button} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {bindActionCreators} from "@reduxjs/toolkit";
import {logOut} from "../../features/auth/authSlice";
import {IoChevronForwardOutline, IoHomeSharp} from "react-icons/all";
import {useProfileQuery} from "../../features/auth/authService";

const Profile = () => {
    const dispatch = useDispatch();
    const authACs = bindActionCreators({logOut}, dispatch);
    let navigate = useNavigate();

    const {data:profile, isLoading} = useProfileQuery();

    return <div className="d-flex flex-column">
        <section className="d-flex justify-content-between mb-3 bg-white">
            <Breadcrumb separator={<IoChevronForwardOutline />}>
                <Breadcrumb.Item key="root">
                    <Link to="/" >
                        <IoHomeSharp />
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Profile</Breadcrumb.Item>
            </Breadcrumb>
        </section>
        <section>
            {
                !isLoading  &&
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column mb-2">
                        <div className="fw-bold">Email:</div>
                        <div>{profile.email}</div>
                    </div>
                    <div className="d-flex flex-column mb-2">
                        <div className="fw-bold">First Name:</div>
                        <div>{profile.firstName}</div>
                    </div>
                    <div className="d-flex flex-column mb-2">
                        <div className="fw-bold">Last Name:</div>
                        <div>{profile.lastName}</div>
                    </div>
                    <div className="d-flex gap-3 mt-3">
                        <Button type="primary"
                                onClick={e=> navigate('/account/profile-edit')}>
                            Edit Profile
                        </Button>
                        <Button
                            onClick={e=> navigate('/account/change-password')}>
                            Change Password
                        </Button>
                        <Button onClick={e=>authACs.logOut()}>
                            Logout
                        </Button>
                    </div>
                </div>
            }
        </section>
    </div>

};

export default Profile;