import React from 'react';
import {Breadcrumb, Button, Form, Input, message, Modal} from "antd";
import {useChangePasswordMutation} from "../../features/auth/authService";
import {IoChevronForwardOutline, IoHomeSharp} from "react-icons/all";
import {Link, useNavigate} from "react-router-dom";
import {bindActionCreators} from "@reduxjs/toolkit";
import {logOut} from "../../features/auth/authSlice";
import {useDispatch} from "react-redux";

const ChangePassword = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [form] = Form.useForm();

    const [ChangePassword, {isLoading}] = useChangePasswordMutation();
    const authACs = bindActionCreators({logOut}, dispatch);

    const onFinish = async (values) => {
        await ChangePassword({...values}).unwrap();
        Modal.success({
            title: 'Password change',
            content: 'Your password has been changed successfully',
            onOk() { authACs.logOut() }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return <div className="d-flex flex-column">
        <section className="d-flex justify-content-between mb-3 bg-white">
            <Breadcrumb separator={<IoChevronForwardOutline />}>
                <Breadcrumb.Item key="root">
                    <Link to="/" >
                        <IoHomeSharp />
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item key="profile">
                    <Link to="/account/profile">
                        Profile
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Change Password</Breadcrumb.Item>
            </Breadcrumb>
        </section>
        <section className="d-flex pt-2">
            <Form form={form}
                  layout="vertical"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className="w-100">
                <Form.Item label="Password"
                           name="password"
                           rules={[{ required: true, message: 'Please input your password' }]}>
                    <Input.Password placeholder="Password" autoComplete="none" />
                </Form.Item>
                <Form.Item label="New Password"
                           name="newPassword"
                           rules={[{ required: true, message: 'Please input your New Password' }]}>
                    <Input.Password placeholder="New Password" autoComplete="none" />
                </Form.Item>
                <Form.Item label="Confirm New Password"
                           name="confirmNewPassword"
                           dependencies={['newPassword']}
                           rules={[{ required: true, message: 'Please confirm your New Password' },
                               ({ getFieldValue }) => ({
                                   validator(_, value) {
                                       if (!value || getFieldValue('newPassword') === value) {
                                           return Promise.resolve();
                                       }
                                       return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                   },
                               })]}>
                    <Input.Password placeholder="Confirm New Password" autoComplete="none" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={isLoading}>Change Password</Button>
                </Form.Item>
            </Form>
        </section>
    </div>
};

export default ChangePassword;