import React, {useEffect} from 'react';
import { Button, Form, Input, Modal, message} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {useCreateAccountMutation} from "../../features/auth/authService";

const Signup = ({ theme, styles }) => {
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [CreateAccount, {isLoading}] = useCreateAccountMutation();

    const onFinish = async (values) => {
        const resultMsg = await CreateAccount({...values, userName:values.email }).unwrap();
        Modal.success({
            title: 'Account has been created successfully',
            content: resultMsg,
            onOk() { navigate('/') }
        });
    };

    const onFinishFailed =  async (errorInfo) => {
        message.error(errorInfo);
    };

    return <section className="bg-home bg-circle-gradiant d-flex align-items-center">
        <div className="bg-overlay bg-overlay-white"/>
        <div className="container form-wrap">
            <section className="form-signin p-4 bg-white rounded shadow">
                <Form form={form}
                      layout="vertical"
                      labelCol={{ span: 12 }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      className="w-100">
                    <h3 className="mb-3">Create Account</h3>
                    <Form.Item label="First name"
                               name="firstName"
                               rules={[{ required: true, message: 'Please input your First name' }]}>
                        <Input placeholder="First name" autoComplete="none" />
                    </Form.Item>
                    <Form.Item label="Last name"
                               name="lastName"
                               rules={[{ required: true, message: 'Please input your Last name' }]}>
                        <Input placeholder="Last name" autoComplete="none" />
                    </Form.Item>
                    <Form.Item label="Email"
                               name="email"
                               rules={[{ required: true, message: 'Please input your email' }]}>
                        <Input placeholder="Email" autoComplete="none" />
                    </Form.Item>
                    <Form.Item label="Password"
                               name="password"
                               rules={[{ required: true, message: 'Please input your password' }]}>
                        <Input.Password placeholder="Password" autoComplete="none" />
                    </Form.Item>
                    <Form.Item label="Confirm Password"
                               name="confirmPassword"
                               dependencies={['password']}
                               rules={[{ required: true, message: 'Please confirm your password' },
                                   ({ getFieldValue }) => ({
                                       validator(_, value) {
                                           if (!value || getFieldValue('password') === value) {
                                               return Promise.resolve();
                                           }
                                           return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                       },
                                   })]}>
                        <Input.Password placeholder="Confirm Password" autoComplete="none" />
                    </Form.Item>
                    <Form.Item>
                        <div className="d-flex justify-content-between align-items-center gap-3 p-1">
                            <Link to="/login">Sign in instead</Link>
                            <Button type="primary" htmlType="submit" loading={isLoading}>Create</Button>
                        </div>
                    </Form.Item>
                </Form>
            </section>
        </div>
    </section>
}

export default Signup;
