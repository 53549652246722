import React, {useState} from "react";
import {useNavigate, useLocation, Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import {useLoginMutation} from "../../features/auth/authService"
import {logOut, setCredentials} from "../../features/auth/authSlice"
import {useAuth} from "../../hooks/useAuth";
import {Alert, Button, Form, Input, message} from "antd";
import {AiOutlineHome} from "react-icons/all";



const LoginForm = ({ theme, styles }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authACs = bindActionCreators({logOut}, dispatch);

  const [login] = useLoginMutation();

  const { authState: { token }, profile: user } = useAuth();
  const bac = bindActionCreators({setCredentials}, dispatch);

  const [error, setError] = useState();
  const [authBusy, setAuthBusy] = useState(false);

  const isAuthenticated = token !== null;

  const onFinish = async (values) => {
    try {
      setError(null);
      setAuthBusy(true);
      const tokenInfo = await login(values).unwrap();
      bac.setCredentials({...tokenInfo});
      setAuthBusy(false);
      navigate("/dashboard");
    } catch (err) {
      setAuthBusy(false);
      message.error('Oh no, there was an error!');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };


  return (
      <section className="bg-home bg-circle-gradiant d-flex align-items-center">
        <div className="bg-overlay bg-overlay-white"/>
        <div className="container form-wrap">
          <div className="form-signin p-4 bg-white rounded shadow">
            {
                isAuthenticated && (
                    <div className="d-flex flex-column justify-content-between" >
                      <h3>
                        {user.firstName} {user.lastName}, you are already signed in.
                      </h3>
                      <div className="d-flex justify-content-end">
                        <Button onClick={() => navigate("/")} icon={<AiOutlineHome />}  className="me-1">
                          Go to Home
                        </Button>
                        <Button type="primary" onClick={e=> authACs.logOut()}>
                          Logout
                        </Button>
                      </div>
                    </div>
                )}
            {
                !isAuthenticated && (
                    <Form onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          layout="vertical">
                      <div className="d-flex flex-column align-items-center mb-3">
                        <img src="/assets/rx-images/maintenanceiq-logo.png" height="50" className="small" alt=""/>
                      </div>
                      <h3 className="mb-3">Login</h3>
                      <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your username!' }]}>
                        <Input placeholder="Please enter your email" />
                      </Form.Item>
                      <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                        <Input.Password />
                      </Form.Item>
                      <Form.Item>
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          <Link to="/signup">Create Account</Link>
                          <Link to="/account/forgot-password">Forgot Password</Link>
                          <Button type="primary"
                                  htmlType="submit"
                                  data-testid="login-button"
                                  loading={authBusy}>
                            Login
                          </Button>
                        </div>
                      </Form.Item>
                      {
                          error && (
                              <Form.Item>
                                <Alert intent="error"
                                       afterClose={() => setError(null)} >
                                  {error}

                                </Alert>
                              </Form.Item>)
                      }
                    </Form>
                )}
          </div>
        </div>
      </section>
  );
}

export default LoginForm;
