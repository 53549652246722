/*Object.prototype.isString = function isString() {
    return typeof this === 'string' || this instanceof String
};*/

import {format} from "date-fns-tz";
import jwt_decode from "jwt-decode";
import LocalTokenService from "../services/localTokenService";

export const isFunction = (source) => source instanceof Function;

export const isNotNullOrUndefined = (source) => typeof source !== 'undefined' && source !== null;

export const isObjectAndNotNull = (source) => typeof source !== 'undefined' && source !== null && typeof source === 'object';

export const isString = (source) => typeof source !== 'undefined' && typeof source === 'string' || source instanceof String

export const isStringAndNotEmpty = (source) => typeof source !== 'undefined' && (typeof source === 'string' || source instanceof String) && source.trim().length > 0

export const formatDateTo = (date, formatStr) => format(new Date(date), formatStr);

export const IsArrayIncludes = (arr, value) => IsArrayWithLength(arr) ? arr.includes(value) : false;

export const IsKeyTrue = (dic, key) => dic && dic[key] === true;

export const AreEqualNumbers = (n1, n2) => IsNotEmpty(n1) && IsNotEmpty(n2) && n1.replace("+", "") === n2.replace("+", "");

export const IsArrayWithLength = (items) => Array.isArray(items) && items.length > 0;

export const IsNotEmpty = (value) => (typeof value !== 'undefined' && value != null && typeof value === 'string' && value.trim().length > 0);

export const IsEmptyObject = (object) => !(IsNotUndefinedOrNotNull(object) && object.constructor === Object && Object.keys(object).length > 0);

export const IsNotUndefinedOrNotNull = (value) => (typeof value !== 'undefined' && value != null);

export const IsFunction = (f) => f !== undefined && f !== null && typeof f === "function";

export const IsNumberGreaterThanZero = (value) => (typeof value !== 'undefined' && typeof value == 'number' && value > 0);

export const ObjectKeysFilter = (source, keys) => Object.keys(source)
    .filter(key => keys.includes(key)).reduce((obj, key) => {
        obj[key] = source[key];
        return obj;
    }, {})


const ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
export const tokenRoles = (token) => {
    try {
        const jwt = isNotNullOrUndefined(token) ? jwt_decode(token) : undefined;
        if (isNotNullOrUndefined(jwt)) return jwt[ROLE] || [];
    } catch {
        return []
    }
}

export const UserHasRoles = (roles, token) => {
    try {
        if(token === undefined) token  = LocalTokenService.getToken().token;
        const tRoles = tokenRoles(token);
        return IsArrayWithLength(tRoles) && tRoles.find(r => roles.includes(r)) !== undefined;
    } catch {
        return false;
    }
};

export const CurrentUserHasRoles = (roles) => {
    try {
        const token  = LocalTokenService.getToken().token;
        const tRoles = tokenRoles(token);
        return IsArrayWithLength(tRoles) && tRoles.find(r => roles.includes(r)) !== undefined;
    } catch {
        return false;
    }
};

