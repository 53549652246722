import React, {useState} from "react";
import {Link, Navigate, useLocation, useSearchParams} from "react-router-dom";
import {IsNotEmpty,IsEmptyObject} from "../../utilities/extensions";
import {Alert, Button, Form, Input, Spin} from "antd";
import {useForgotPasswordMutation} from "../../features/auth/authService";
import {loadingIcon, openNotificationWithIcon, SingleError} from "../../utilities/uiExtensions";

const STEP_START = "Start";
const STEP_PROCESS = "Process";
const STEP_COMPLETED = "Completed";

const ForgotPassword = ({ theme, styles }) => {
    const [step, setStep] = useState(STEP_START);
    const [email, setEmail] = useState('');

    const [forgotPassword] = useForgotPasswordMutation();

    const onFinish = async({email}) => {
        setEmail(email);
        await sendForgotEmail(email);
    }

    const sendForgotEmail = async (emailInput) =>{
        let hasError = false;
        try {
            setStep(STEP_PROCESS);
            await forgotPassword({email: emailInput}).unwrap();
        } catch (error) {
            openNotificationWithIcon("error", "Error", SingleError(error));
            hasError = true;
        }
        setStep(hasError ? STEP_START : STEP_COMPLETED);
    }

    const onFinishFailed = () => {

    }

    return  <section className="bg-home d-flex align-items-center bg-color" >
        <div className="container form-wrap">
            <div className="p-4 bg-white rounded shadow">
                {
                    step === STEP_PROCESS &&
                    <div className="d-flex flex-column justify-content-between gap-3" >
                        <Alert message="Processing..."
                               type="info"
                               icon={<Spin indicator={loadingIcon}  />}
                               showIcon />
                    </div>
                }
                {
                    step === STEP_COMPLETED &&
                    <div className="d-flex flex-column justify-content-between gap-3" >
                        <Alert message="Check your email for a link to reset your password."
                               type="info"/>
                        <div className="d-flex align-items-center justify-content-end gap-3">
                            <Button type="primary" onClick={e=>sendForgotEmail(email)}>
                                Resend Link
                            </Button>
                            <Link to="/login">Login</Link>
                        </div>
                    </div>
                }
                {
                    step === STEP_START &&
                    <Form autoComplete="off"
                          layout="vertical"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}>
                        <div className="d-flex flex-column align-items-center mb-3">
                            <img src="/assets/rx-images/maintenanceiq-logo.png" height="50" className="small" alt=""/>
                        </div>
                        <h4 className="mb-3">Reset Password</h4>
                        <p><strong>Forgot Password?</strong><br/>Enter your email and we will send you a password reset link.</p>
                        <Form.Item label="Email" name="email" rules={[
                            { required: true,  type: "email", message: 'Please input your email' } ]}
                                   initialValue={email}>
                            <Input placeholder="Please enter your email" />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <div className="d-flex justify-content-end align-items-center gap-3">
                                <Link to="/login">Login</Link>
                                <Button type="primary"
                                        htmlType="submit"
                                        loading={step === STEP_PROCESS}>
                                    Reset Password
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                }
            </div>
        </div>
    </section>


}

export default ForgotPassword;
