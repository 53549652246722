import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Alert, Button, Spin} from "antd";
import {loadingIcon} from "../../utilities/uiExtensions";
import { useUnsubscribeEmailMutation } from '../../features/auth/authService';
import {AiOutlineHome} from "react-icons/all";

const UnsubscribeEmail = ({ theme, styles }) => {
    const [processing, setProcessing] = useState(true);
    const [result, setResult] = useState({msg:'', success:true});
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [unsubscribeEmail] = useUnsubscribeEmailMutation();

    useEffect(()=>{
        const unsubscribeEmailAsync = async () => {
            const {message,success} = await unsubscribeEmail(Object.fromEntries([...searchParams])).unwrap();
            setResult({message,success});
            setProcessing(false);
        };
        unsubscribeEmailAsync();
    },[]);


   return  <section className="bg-home d-flex align-items-center bg-color" >
        <div className="container form-wrap">
            <div className="p-4 bg-white rounded shadow">
                {
                    processing &&
                    <div className="d-flex flex-column justify-content-between gap-3" >
                        <div className="d-flex flex-column align-items-center mb-3">
                            <img src="/assets/rx-images/maintenanceiq-logo.png" height="50" className="small" alt=""/>
                        </div>
                        <Alert message="Processing..."
                               type="info"
                               icon={<Spin indicator={loadingIcon}  />}
                               showIcon />
                    </div>
                }
                {
                    !processing &&
                    <div className="d-flex flex-column align-items-center mb-3">
                        <div className="d-flex flex-column align-items-center mb-3">
                            <img src="/assets/rx-images/maintenanceiq-logo.png" height="50" className="small" alt=""/>
                        </div>
                        <h4 className="mb-3">Unsubscribe Email</h4>
                        <div className="d-flex align-items-center"><p>{result.message}</p></div>
                        <Button onClick={() => navigate("/")} icon={<AiOutlineHome />}  className="me-1">
                            Go to Home
                        </Button>
                    </div>
                }
            </div>
        </div>
    </section>

}

export default UnsubscribeEmail;
