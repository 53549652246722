import React from 'react';
import {useSpinnerProvider} from "../../components/Spinner/SpinnerProvider";
import {Button} from "antd";

const Dashboard= () => {
    //const {showSpinner} = useSpinnerProvider();
    //const auth = useSelector(state => state.auth);

    return (
        <div>

        </div>
    );
}

export default Dashboard;
