import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoMatch from "./components/Utils/NoMatch";
import ProtectedRoutes from "./pages/Auth/ProtectedRoutes";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import Dashboard from "./pages/Dashboard/Dashboard";
import ResetPassword from "./pages/Auth/ResetPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import {AccessDenied} from "./components/Layout/AccessDenied";
import ConfirmEmail from "./pages/Auth/ConfirmEmail";
import UnsubscribeEmail from "./pages/Auth/UnsubscribeEmail";
import Profile from "./pages/Auth/Profile";
import ProfileEdit from "./pages/Auth/ProfileEdit";
import ChangePassword from "./pages/Auth/ChangePassword";

const App = ({ theme }) => {

   useEffect(() => { window.process = { ...window.process, }; }, []);

   return (
      <BrowserRouter>
         <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
               <Route path="/dashboard" element={<Dashboard />} />
               <Route path="/account" >
                  <Route path="profile" element={<Profile />}/>
                  <Route path="profile-edit" element={<ProfileEdit />}/>
                  <Route path="change-password" element={<ChangePassword />}/>
               </Route>
               <Route path="*" element={<NoMatch />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="/account/reset-password" element={<ResetPassword />} />
            <Route path="/account/forgot-password" element={<ForgotPassword />} />
            <Route path="/communication/preferences/confirm-email" element={<ConfirmEmail />} />
            <Route path="/communication/preferences/unsubscribe-email" element={<UnsubscribeEmail />} />
            <Route path="*" element={<NoMatch />} />
         </Routes>
      </BrowserRouter>
   );
}

export default App;
