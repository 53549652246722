import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import commonSlice, { SLICE_COMMON_NAME } from "./common/commonUi";
import authReducer, { SLICE_AUTH_NAME } from '../features/auth/authSlice'
import { baseApiService } from "./baseApiService";
import signalRMiddleware from "./middlewares/signalRMiddleware";



export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
    configureStore({
        reducer: {
            [SLICE_COMMON_NAME]: commonSlice,
            [baseApiService.reducerPath]: baseApiService.reducer,
            [SLICE_AUTH_NAME]: authReducer,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApiService.middleware,  signalRMiddleware),
        devTools: true,

    });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppSelector = () => useSelector;
