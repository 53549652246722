import React, {useEffect} from 'react';
import {Breadcrumb, Button, Form, Input, message} from "antd";
import {useAuth} from "../../hooks/useAuth";
import {Link} from "react-router-dom";
import {useProfileQuery, useUpdateProfileMutation} from "../../features/auth/authService";
import {IoChevronForwardOutline, IoHomeSharp} from "react-icons/all";

const ProfileEdit = () => {

    const [form] = Form.useForm();
    const {data:profile, isLoading} = useProfileQuery();

    const [UpdateProfile, {isLoading: updateInProcess}] = useUpdateProfileMutation();

    useEffect(()=>{
        form.setFieldsValue({...profile})
    }, [profile])

    const onFinish = async (values) => {
        await UpdateProfile({...profile,...values }).unwrap();
        message.info('Profile has been updated!');
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return <div className="d-flex flex-column">
        <section className="d-flex justify-content-between mb-3 bg-white">
            <Breadcrumb separator={<IoChevronForwardOutline />}>
                <Breadcrumb.Item key="root">
                    <Link to="/" >
                        <IoHomeSharp />
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item key="profile">
                    <Link to="/account/profile">
                        Profile
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Profile</Breadcrumb.Item>
            </Breadcrumb>
        </section>
        <section className="d-flex pt-2">
            <Form form={form}
                  layout="vertical"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className="w-100">
                <Form.Item label="First name"
                           name="firstName"
                           rules={[{ required: true, message: 'Please input your First name' }]}>
                    <Input placeholder="First name" autocomplete="none" />
                </Form.Item>
                <Form.Item label="Last name"
                           name="lastName"
                           rules={[{ required: true, message: 'Please input your Last name' }]}>
                    <Input placeholder="Last name" autocomplete="none" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={updateInProcess}>Update</Button>
                </Form.Item>
            </Form>
        </section>
    </div>
}

export default ProfileEdit;