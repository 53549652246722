import React, { useState,useEffect } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { Alert, Button, Form, Input, Spin } from 'antd';
import { useResetPasswordMutation } from '../../features/auth/authService';
import { loadingIcon,openNotificationWithIcon,SingleError,} from '../../utilities/uiExtensions';
import {
   CheckCircleOutlined,
   CloseCircleOutlined,
   LoadingOutlined,
} from '@ant-design/icons';
import {checkPasswordLength,checkSpecialCharacters,checkUppercase,checklowercase,checkNumber} from './passwordCirteria';

const ResetPassword = ({ theme, styles }) => {
   const [actionInProgress, setActionInProgress] = useState(false);
   const [processMessage, setProcessMessage] = useState(false);
   const [searchParams, setSearchParams] = useSearchParams();
   const token = searchParams.get('token');
   const email = searchParams.get('email');
   const location = useLocation();
   const [charNumberValid, setcharNumberValid] = useState(false);
   const [specialCharValid, setspecialCharValid] = useState(false);
   const [uppercaseValid, setuppercaseValid] = useState(false);
   const [lowercaseValid, setlowercaseValid] = useState(false);
   const [numberValid, setnumberValid] = useState(false);
   const [btnShowStatus ,setbtnShowStatus] =useState(true);
   const [newPass ,setNewPss] = useState('');
   const [compairePass ,setCompairePass] = useState('');
   const [compairePassStatus , setCompairePassStatus] = useState(false);


   const [resetPassword] = useResetPasswordMutation();

   //should we clearTimeout
   let timer;
   const onFinish = async ({ password }) => {
      try {
         if(!btnShowStatus && !compairePassStatus){
         setActionInProgress(true);
         setProcessMessage('Processing...');
         await resetPassword({
            password,
            token: encodeURI(token),
            email: email,
         }).unwrap();
         setProcessMessage(
            'Your password has been updated successfully! You will be redirected to the login page',
         );
         timer = setTimeout(() => (window.location.href = '/dashboard'), 2500);
         }
      } catch (err) {
         openNotificationWithIcon('error', 'Error', SingleError(err.data));
         setActionInProgress(false);
         setcharNumberValid(false);
         setspecialCharValid(false);
         setuppercaseValid(false);
         setnumberValid(false);
         setlowercaseValid(false);
         setCompairePassStatus(false);
         setCompairePass('');
         setNewPss('');
      }
   };

   const onFinishFailed = () => {};

   const handleChange = (e,password) => {
      if(password === "newpass"){
         setNewPss(e.target.value)
         checkPasswordLength(e.target.value) ?   setcharNumberValid(true) :    setcharNumberValid(false);
         checkSpecialCharacters(e.target.value) ? setspecialCharValid(true) :  setspecialCharValid(false);
         checkUppercase(e.target.value) ? setuppercaseValid(true):             setuppercaseValid(false);
         checkNumber(e.target.value) ?   setnumberValid(true) :                setnumberValid(false);
         checklowercase(e.target.value)?   setlowercaseValid(true) :           setlowercaseValid(false);

      }
      if(password === "repass"){
         setCompairePass(e.target.value);

      }

   };

   useEffect(()=>{
    if(charNumberValid && specialCharValid && uppercaseValid && lowercaseValid && numberValid && (newPass === compairePass)){
        setbtnShowStatus(false);
        setCompairePassStatus(false);
      }
      else{
         if(newPass !== compairePass && compairePass.toString().length > 0){
            setCompairePassStatus(true);
         }
        setbtnShowStatus(true);
      }
   },[charNumberValid,specialCharValid,uppercaseValid,lowercaseValid,numberValid,compairePass,newPass])


   if (token) {
      return (
         <section className="bg-home d-flex align-items-center bg-color">
            <div className="container form-wrap">
               <div className="p-4 bg-white rounded shadow">
                  {actionInProgress && (
                     <div className="d-flex flex-column justify-content-between">
                        <Alert
                           message={processMessage}
                           type="info"
                           icon={<Spin indicator={loadingIcon} />}
                           showIcon
                        />
                     </div>
                  )}
                  {!actionInProgress && (
                     <Form
                        autoComplete="off"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                     >
                        <div className="d-flex flex-column align-items-center mb-3">
                           <img
                              src="/assets/rx-images/maintenanceiq-logo.png"
                              height="50"
                              className="small"
                              alt=""
                           />
                        </div>
                        <h4 className="mb-3">Reset Password</h4>
                        {/* 1 lowercase letter  */}
                        <div className={lowercaseValid ? "vaildColor" : "invalidColor"}           
                        >
                           {lowercaseValid ? (
                              <CheckCircleOutlined
                                 style={{ display: 'inline-flex' }}
                              />
                           ) : (
                              <CloseCircleOutlined
                                 style={{ display: 'inline-flex' }}
                              />
                           )}
                           <span  className='m-2'>
                              1 Lowercase Letter
                           </span>
                        </div>

                        {/*8-20 characters  */}
                        <div className={charNumberValid ? 'vaildColor':'invalidColor'}
                          
                        >
                           {charNumberValid ? (
                              <CheckCircleOutlined
                                 style={{ display: 'inline-flex' }}
                              />
                           ) : (
                              <CloseCircleOutlined
                                 style={{ display: 'inline-flex' }}
                              />
                           )}
                           <span  className='m-2'>
                              12 characters
                           </span>
                        </div>

                        {/*special character  */}
                        <div className={specialCharValid ? 'vaildColor':'invalidColor'}
                          
                        >
                           {specialCharValid ? (
                              <CheckCircleOutlined
                                 style={{ display: 'inline-flex' }}
                              />
                           ) : (
                              <CloseCircleOutlined
                                 style={{ display: 'inline-flex' }}
                              />
                           )}
                           <span  className='m-2'>
                              1 Special Character
                           </span>
                        </div>

                        {/* 1 uppercase letter  */}
                        <div className={uppercaseValid ? 'vaildColor':'invalidColor'}
                          
                        >
                           {uppercaseValid ? (
                              <CheckCircleOutlined
                                 style={{ display: 'inline-flex' }}
                              />
                           ) : (
                              <CloseCircleOutlined
                                 style={{ display: 'inline-flex' }}
                              />
                           )}
                           <span  className='m-2'>
                              1 Uppercase Letter
                           </span>
                        </div>



                        {/* 1 number  */}
                        <div className={numberValid ? 'vaildColor':'invalidColor'}
                        >
                           {numberValid ? (
                              <CheckCircleOutlined
                                 style={{ display: 'inline-flex' }}
                              />
                           ) : (
                              <CloseCircleOutlined
                                 style={{ display: 'inline-flex' }}
                              />
                           )}
                           <span className='m-2'>
                           1 Number
                           </span>
                        </div>


                        <Form.Item
                           label="New Password"
                           name="password"
                           rules={[
                              {
                                 required: true,
                                 message: 'Please input your new password!',
                              },
                              {
                                 min: 6,
                                 message:
                                    'Password must be between 6 and 255 characters',
                              },
                              {
                                 max: 255,
                                 message:
                                    'Password must be between 6 and 255 characters',
                              },
                           ]}
                        >
                           <Input
                              type="password"
                              placeholder="Please enter your new password"
                              onChange={(e) => {
                                 handleChange(e,"newpass");
                              }}
                           />
                        </Form.Item>
                        <Form.Item
                           label="Re-enter Your New Password"
                           name="confirmPassword"
                        >
                           <Input
                              type="password"
                              placeholder="Re-enter Your New Password"
                              onChange={(e) => {
                                 handleChange(e,"repass");
                              }}
                           />
                          {compairePassStatus ? <div className='invalidColor'>The two passwords that you entered do not match!</div> :""}
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                           <div className="d-flex justify-content-end align-items-center gap-1">
                              <Button
                                 type="primary"
                                 htmlType="submit"
                                 data-testid="login-button"
                                 loading={actionInProgress}
                                 disabled={btnShowStatus}
                              >
                                 Submit
                              </Button>
                           </div>
                        </Form.Item>
                     </Form>
                  )}
               </div>
            </div>
         </section>
      );
   } else {
      return <Navigate to="/" state={{ from: location }} replace />;
   }
};

export default ResetPassword;
