import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../appStore";
import LocalTokenService from "../../services/localTokenService";
import {UserResponse} from "../../models/UserResponse";
import {Profile} from "../../models/Profile";
import {ClearMenuItems, ReLoadMenuItems} from "../../components/Layout/MasterLayout/SideMenuItems";
import {authApi} from "./authService";

const {matchFulfilled} = authApi.endpoints.profile;
export const DEFAULT_AUTH_STATE= { profile: null, token:null, refreshToken:null, refreshTokenExpiryTime:null } as AuthState;

const localAuth = LocalTokenService.getToken();

type AuthState = {
    profile: Profile | null
    token: string | null
    refreshToken: string | null,
    refreshTokenExpiryTime: Date | null,
}
//TODO: remove this and use authService.ts
export const SLICE_AUTH_NAME = "auth";
const slice = createSlice({
    name: SLICE_AUTH_NAME,
    initialState: { profile: localAuth.profile, token: localAuth.token, refreshToken: localAuth.refreshToken, refreshTokenExpiryTime: localAuth.refreshTokenExpiryTime } as AuthState,
    reducers: {
        setCredentials: ( state, { payload: { profile, token, refreshToken, refreshTokenExpiryTime } }: PayloadAction<UserResponse>) => {
            state.profile = profile;
            state.token = token;
            state.refreshToken = refreshToken;
            state.refreshTokenExpiryTime = refreshTokenExpiryTime;
            LocalTokenService.setToken({profile, token, refreshToken, refreshTokenExpiryTime });
            ReLoadMenuItems();
        },
        setUserProfile:( state, { payload }: PayloadAction<any>) => {
            const { token, refreshToken, refreshTokenExpiryTime } = state;
            LocalTokenService.setToken({payload, token, refreshToken, refreshTokenExpiryTime });
            ReLoadMenuItems();
        },
        logOut:() => {
            ClearMenuItems();
            LocalTokenService.setToken({...DEFAULT_AUTH_STATE });
            return DEFAULT_AUTH_STATE;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(matchFulfilled,(state, { payload }) => {
            state.profile = payload;
        })
    }
})

export const { setCredentials, setUserProfile, logOut } = slice.actions

export default slice.reducer

export const selectProfile = (state: RootState) => state[SLICE_AUTH_NAME].profile;

export const selectAuthState= (state: RootState) => state[SLICE_AUTH_NAME];
